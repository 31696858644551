<template>
  <b-card
    no-body
  >
    <template #header>
      <b-container fluid>
        <b-row cols-md="12">
          <b-col
            class="mb-1"
          >
            <strong>Asset : </strong>{{ asset.name }} ({{ asset.assetID }})
          </b-col>
          <b-col
            class="mb-1"
          >
            <strong>Device : </strong>{{ asset.deviceID }}
          </b-col>
          <b-col
            class="mb-1"
          >
            <strong>Site : </strong>{{ asset.siteName }}
          </b-col>
          <b-col
            class="mb-1"
          >
            <strong>Building : </strong>{{ asset.buildingName }}
          </b-col>
          <b-col
            class="mb-1"
          >
            <strong>Line : </strong>{{ asset.lineName }}
          </b-col>
          <b-col
            cols="12"
            md="auto"
            class="mb-1"
          >
            <div class="d-flex justify-content-end">
              <b-button-group
                size="lg"
                class="mx-1"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="`gradient-${fftlog ? 'primary' : 'secondary'} `"
                  class="btn-icon"
                  :to="{ name: 'apps-assets-log', params: { 'assetID': asset.assetID }}"
                >
                  <BIconBarChartLine
                    class="bar-chart-line"
                  />
                </b-button>
              </b-button-group>
              <!-- <b-button-group
                size="lg"
                class="mx-1"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="`gradient-${threeDimensional ? 'primary' : 'secondary'} `"
                  class="btn-icon"
                  :to="{ name: 'apps-assets-3d', params: { 'assetID': asset.assetID }}"
                >
                  <feather-icon icon="BoxIcon" />
                </b-button>
              </b-button-group> -->
              <b-button-group
                size="lg"
                class="mx-1"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="`gradient-${dominantFrequency ? 'primary' : 'secondary'} `"
                  class="btn-icon"
                  :to="{ name: 'apps-assets-dominantfreq', params: { 'assetID': asset.assetID }}"
                >
                  <BIconGraphUp
                    class="graph-up"
                  />
                </b-button>
              </b-button-group>
              <b-button-group
                v-if="allowHistory"
                size="lg"
                class="mx-1"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="`gradient-${trainingData ? 'primary' : 'secondary'} `"
                  class="btn-icon"
                  :to="{ name: 'apps-assets-training', params: { 'assetID': asset.assetID }}"
                >
                  <BIconDiagram3
                    class="diagram-3"
                  />
                </b-button>
              </b-button-group>
              <b-button-group
                size="lg"
                class="mx-1"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="`gradient-${telemetry ? 'primary' : 'secondary'} `"
                  class="btn-icon"
                  :to="{ name: 'apps-assets-telemetry', params: { 'assetID': asset.assetID }}"
                >
                  <BIconBroadcastPin
                    class="broadcast-pin"
                  />
                </b-button>
              </b-button-group>
              <b-button-group
                size="lg"
                class="mx-1"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="`gradient-${alerts ? 'primary' : 'secondary'} `"
                  class="btn-icon"
                  :to="{ name: 'apps-assets-alerts', params: { 'assetID': asset.assetID }}"
                >
                  <feather-icon
                    icon="BellIcon"
                    :badge="alertsCount"
                    badge-classes="bg-danger"
                    size="21"
                  />
                </b-button>
              </b-button-group>
              <b-button-group
                v-if="allowHistory"
                size="lg"
                class="mx-1"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="`gradient-${history ? 'primary' : 'secondary'} `"
                  class="btn-icon"
                  :to="{ name: 'apps-assets-history', params: { 'assetID': asset.assetID }}"
                >
                  <BIconClockHistory
                    class="clock-history"
                  />
                </b-button>
              </b-button-group>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BContainer, BButtonGroup, BIconClockHistory, BIconGraphUp, BIconBarChartLine, BIconBroadcastPin, BIconDiagram3,
} from 'bootstrap-vue'
import { computed } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { allowHistoryView } from '@/api/auth/utils'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BContainer,
    BButtonGroup,
    BIconClockHistory,
    BIconGraphUp,
    BIconBarChartLine,
    BIconBroadcastPin,
    BIconDiagram3,

  },
  props: {
    asset: {
      type: Object,
      required: true,
    },
    threeDimensional: {
      type: Boolean,
      default: false,
    },
    fftlog: {
      type: Boolean,
      default: false,
    },
    telemetry: {
      type: Boolean,
      default: false,
    },
    history: {
      type: Boolean,
      default: false,
    },
    dominantFrequency: {
      type: Boolean,
      default: false,
    },
    alerts: {
      type: Boolean,
      default: false,
    },
    trainingData: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const allowHistory = allowHistoryView()
    const getAlertsCount = asset => {
      const { faults } = asset
      if (faults) {
        if (faults.includes(1001)) {
          return faults.length - 1
        }
        return faults.length
      }
      return 0
    }

    const alertsCount = computed(() => getAlertsCount(props.asset))

    return {
      allowHistory,
      alertsCount,
    }
  },
}
</script>
